// 定数定義
export const PAGE_NAME = "mypage.gaaat.com";

export const LIST_TYPE_ARTIST = "LIST_TYPE_ARTIST";
export const LIST_TYPE_EVENT = "LIST_TYPE_EVENT";
export const LIST_TYPE_ART = "LIST_TYPE_ART";
export const LIST_TYPE_GOODS = "LIST_TYPE_GOODS";
export const LIST_TYPE_MYART = "LIST_TYPE_MYART";

// Follow contents type (フォロー登録/解除で使用)
export const FOLLOW_CONTENTS_TYPE_ARTIST = 1;
export const FOLLOW_CONTENTS_TYPE_EVENT = 2;
export const FOLLOW_CONTENTS_TYPE_PRODUCT = 3;

// Code type (汎用コード取得で使用)
export const GENERAL_CODE_TYPE_COUNTRY = 1;
export const GENERAL_CODE_TYPE_PREFECTURES = 2;

// Language type（言語の比較で使用）
export const LANGUAGE_CODE_TYPE_JAPANESE = "ja";

// No Image URL(画像取得できない場合に使用)
export const NO_IMAGE_URL = "https://dev-mypage-pic.s3.ap-northeast-1.amazonaws.com/sample_image.jpeg";
